<template>
  <div>
    <homeMb v-if="screenWidth < 765"  class="is-mb" />
    <homePc v-else class="is-pc" />
  </div>
</template>

<script>
import homePc from '@/views_pc/recommend/index2'
import homeMb from '@/views_mb/recommend/index2'
export default {
  components: { homePc, homeMb },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize= () => {
        return (() => {
            window.screenWidth = document.body.clientWidth
            that.screenWidth = window.screenWidth
        })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style lang='scss' scoped>
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media(max-width:766px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
</style>
